import { createAction, createSlice } from '@reduxjs/toolkit'
import clone from 'just-clone'

import {
  constants,
  FILTER_CAPACITY,
  FILTER_INCLUSIVE_BENEFITS,
  FILTER_PAGE,
  FILTER_RANGES,
} from '../../lib/constants'
import { FILTERS } from '../../lib/filters'

export const INITIAL_STATE = {
  availableFilters: {
    budget: [
      {
        minMonthly: 0,
        maxMonthly: 9999999,
        minInitial: 0,
        maxInitial: 9999999,
      },
    ],
    mileages: [],
    terms: [],
    vehiclePriceTags: [],
    vehicleTags: [],
  },
  bodyStyles: FILTERS.bodyStyles,
  capacity: {
    payload: {
      min: null,
      max: null,
    },
    loadLength: {
      min: null,
      max: null,
    },
    loadHeight: {
      min: null,
      max: null,
    },
    loadWidth: {
      min: null,
      max: null,
    },
  },
  deals: [],
  doors: FILTERS.doors,
  driveTypes: FILTERS.driveTypes,
  features: FILTERS.features,
  fuelTypes: FILTERS.fuelTypes,
  hasChanged: false,
  includeVat: true,
  inStock: false,
  leaseTypes: 2,
  manufacturers: FILTERS.manufacturers,
  pagination: {
    pageSize: 0,
    page: 0,
  },
  performance: FILTERS.performance,
  pricesTotal: 0,
  ranges: [],
  resultsTotal: 0,
  roofHeight: FILTERS.roofHeight,
  savedSearchReference: '',
  searchParameters: {
    bodyStyles: [],
    doors: [],
    driveTypes: [],
    features: [],
    fuelTypes: [],
    grouping: 3,
    inStock: 0,
    leaseTypes: [2],
    manufacturers: [],
    mileages: [5000, 8000, 10000],
    monthlyPaymentMax: '500.00',
    performance: [],
    ranges: [],
    roofHeight: [],
    searchTerm: '',
    seats: [],
    sortBy: 1,
    spec: [],
    terms: [],
    transmissionTypes: [],
    vehiclePriceTags: [],
    vehicleTags: [],
    vehicleTypes: [1],
    wheelbase: [],
    pageSize: 24,
  },
  searchTerm: '',
  seats: FILTERS.seats,
  spec: FILTERS.spec,
  transmissionTypes: FILTERS.transmissionTypes,
  vehicleTotal: 0,
  wheelbase: FILTERS.wheelbase,
  isSearchSaved: false,
}

const searchSlice = createSlice({
  name: 'search',
  initialState: INITIAL_STATE,
  reducers: {
    toggleVat: (state, action) => {
      state.includeVat = action.payload
    },
    toggleInStock: (state, action) => {
      state.inStock = action.payload
    },
    toggleGrouping: (state, action) => {
      state.grouping = action.payload
    },
    updateInStockFromLeaseProfile: (state, action) => {
      state.inStock = action.payload
      state.searchParameters.inStock = action.payload ? 1 : 0
    },
    storeSearchTotals: (state, action) => {
      state.pricesTotal = action.payload.pricesTotal
      state.resultsTotal = action.payload.resultsTotal
      state.vehicleTotal = action.payload.vehicleTotal
    },
    storePagination: (state, action) => {
      state.pagination = action.payload
    },
    storeAvailableFilters: (state, action) => {
      state.availableFilters = {
        ...action.payload,
        budget: action.payload.budget,
        mileages: action.payload.mileages,
        terms: action.payload.terms,
        vehicleTags: action.payload.vehicleTags,
        vehiclePriceTags:
          action.payload.vehiclePriceTags?.length > 1
            ? action.payload.vehiclePriceTags.map(vpt => clone(vpt))
            : action.payload.vehiclePriceTags,
      }
    },
    storeSearchResults: (state, action) => {
      state.deals = action.payload
    },
    storeVehicleRanges: (state, action) => {
      state.ranges = action.payload ? [...action.payload] : state.ranges
    },
    removeVehicleRanges: (state, action) => {
      state.ranges = [...state.ranges.filter(range => range.manufacturer !== action.payload)]
    },
    storeHomepageSearchParameters: (state, action) => {
      state.searchParameters[action.payload.filter] = action.payload.value
    },
    storeSearchParameters: (state, action) => {
      state.searchParameters[action.payload.filter] = action.payload.value
    },
    removeSearchParameters: (state, action) => {
      state.searchParameters = Object.assign(
        {},
        ...Object.entries(state.searchParameters)
          .filter(([key]) => key !== action.payload.filter)
          .map(([key, value]) => ({ [key]: value })),
      )
    },
    storeSearchMultiParameters: (state, action) => {
      if (
        state.searchParameters[action.payload.filter].filter(item => item === action.payload.value)
          .length > 0
      ) {
        state.searchParameters[action.payload.filter] = state.searchParameters[
          action.payload.filter
        ].filter(item => item !== action.payload.value)
      } else {
        state.searchParameters[action.payload.filter] = state.searchParameters[
          action.payload.filter
        ].concat(action.payload.value)
      }
    },
    clearSelectedRangesForSelectedManufacturer: (state, action) => {
      state.searchParameters.ranges = state.searchParameters.ranges.filter(
        range => action.payload.indexOf(range) === -1,
      )
    },
    storeLeaseType: (state, action) => {
      state.leaseTypes = action.payload
      state.searchParameters = {
        ...state.searchParameters,
        leaseTypes: [action.payload],
      }
    },
    clearPageNumber: state => {
      state.searchParameters = Object.assign(
        {},
        ...Object.entries(state.searchParameters)
          .filter(([key]) => key !== FILTER_PAGE)
          .map(([key, value]) => ({ [key]: value })),
      )
    },
    storeSearchFilter: (state, action) => {
      state[action.payload.filter] = action.payload.params
    },
    clearSelectedSearchFilter: (state, action) => {
      if (action.payload === FILTER_CAPACITY) {
        return {
          ...state,
          searchParameters: Object.assign(
            {},
            ...Object.entries(state.searchParameters)
              .filter(([key]) => !constants.capacityFilterList.includes(key))
              .map(([key, value]) => ({ [key]: value })),
          ),
        }
      } else if (action.payload === FILTER_INCLUSIVE_BENEFITS) {
        return {
          ...state,
          searchParameters: Object.assign(
            {},
            ...Object.entries(state.searchParameters).map(([key, value]) => {
              if (key === 'vehiclePriceTags') {
                return { [key]: value.filter(tag => tag.vehiclePriceTagCategoryId === 3) }
              }
              return { [key]: value }
            }),
          ),
        }
      } else {
        state.searchParameters[action.payload] = INITIAL_STATE.searchParameters[action.payload]
      }
    },
    clearSearchParameters: state => {
      state.inStock = false
      state.searchParameters = {
        ...INITIAL_STATE.searchParameters,
      }
    },
    clearSelectedRangeSearchFilter: state => {
      state.searchParameters[FILTER_RANGES] = INITIAL_STATE.searchParameters[FILTER_RANGES]
    },
    storeTextSearch: (state, action) => {
      state.searchTerm = action.payload ? action.payload : ''
      state.searchParameters.searchTerm = action.payload ? action.payload : ''
    },
    storeSortBy: (state, action) => {
      state.searchParameters.sortBy = action.payload
    },
    storeSavedSearchReference: (state, action) => {
      state.savedSearchReference = action.payload
    },
    storeSearchFiltersHaveChanged: (state, action) => {
      state.hasChanged = action.payload
    },
    clearTextSearch: state => {
      state.searchParameters.searchTerm = INITIAL_STATE.searchParameters.searchTerm
      state.searchTerm = INITIAL_STATE.searchTerm
    },
    hydrateSearchParameters: (state, action) => {
      state.inStock = action.payload.inStock ? action.payload.inStock : INITIAL_STATE.inStock
      state.searchParameters = {
        ...INITIAL_STATE.searchParameters,
        ...action.payload,
        searchTerm: action.payload.searchTerm
          ? action.payload.searchTerm
          : INITIAL_STATE.searchParameters.searchTerm,
        monthlyPaymentMax: action.payload.monthlyPaymentMax
          ? action.payload.monthlyPaymentMax
          : 9999999,
      }
      state.searchTerm = action.payload.searchTerm
        ? action.payload.searchTerm
        : INITIAL_STATE.searchParameters.searchTerm
    },
    toggleBudgetPrices: (state, action) => {
      state.searchParameters = {
        ...state.searchParameters,
        [action.payload.filter]: action.payload.value,
      }
    },
    storeVehicleTypes: (state, action) => {
      state.searchParameters = {
        ...state.searchParameters,
        vehicleTypes: [action.payload],
      }
    },
    storeGroupedVehiclePriceTag: (state, action) => {
      state.searchParameters = {
        ...state.searchParameters,
        vehiclePriceTags: [
          ...state.searchParameters.vehiclePriceTags.map(vpt => Object.assign({}, vpt)),
          {
            vehiclePriceTagCategoryId: action.payload,
            vehiclePriceTagIds: [],
          },
        ],
      }
    },
    removeGroupedVehiclePriceTag: (state, action) => {
      state.searchParameters = {
        ...state.searchParameters,
        vehiclePriceTags: [
          ...state.searchParameters.vehiclePriceTags
            .filter(tag => tag.vehiclePriceTagCategoryId !== action.payload)
            .map(vpt => Object.assign({}, vpt)),
        ],
      }
    },
    storeUngroupedVehiclePriceTag: (state, action) => {
      if (
        state.searchParameters.vehiclePriceTags.filter(
          tag => tag.vehiclePriceTagCategoryId === action.payload.category,
        ).length > 0
      ) {
        const category = state.searchParameters.vehiclePriceTags
          .filter(tag => tag.vehiclePriceTagCategoryId === action.payload.category)
          .map(vpt => Object.assign({}, vpt))[0]
        const idExists =
          category.vehiclePriceTagIds.filter(id => id === action.payload.tag).length > 0
        const idsSelected = category.vehiclePriceTagIds.length

        if (idExists && idsSelected > 1) {
          state.searchParameters = {
            ...state.searchParameters,
            vehiclePriceTags: [
              ...state.searchParameters.vehiclePriceTags.filter(
                tag => tag.vehiclePriceTagCategoryId !== action.payload.category,
              ),
              {
                vehiclePriceTagCategoryId: action.payload.category,
                vehiclePriceTagIds: [
                  ...category.vehiclePriceTagIds.filter(tag => tag !== action.payload.tag),
                ],
              },
            ],
          }
        } else if (idExists && idsSelected === 1) {
          state.searchParameters = {
            ...state.searchParameters,
            vehiclePriceTags: [
              ...state.searchParameters.vehiclePriceTags.filter(
                tag => tag.vehiclePriceTagCategoryId !== action.payload.category,
              ),
            ],
          }
        } else {
          state.searchParameters = {
            ...state.searchParameters,
            vehiclePriceTags: [
              ...state.searchParameters.vehiclePriceTags.filter(
                tag => tag.vehiclePriceTagCategoryId !== action.payload.category,
              ),
              {
                vehiclePriceTagCategoryId: action.payload.category,
                vehiclePriceTagIds: [...category.vehiclePriceTagIds, action.payload.tag],
              },
            ],
          }
        }
      } else {
        state.searchParameters = {
          ...state.searchParameters,
          vehiclePriceTags: [
            ...state.searchParameters.vehiclePriceTags,
            {
              vehiclePriceTagCategoryId: action.payload.category,
              vehiclePriceTagIds: [action.payload.tag],
            },
          ],
        }
      }
    },
    removeUngroupedVehiclePriceTag: (state, action) => {
      state.searchParameters.vehiclePriceTags = state.searchParameters.vehiclePriceTags.filter(
        tag => tag.vehiclePriceTagCategoryId !== action.payload,
      )
    },
    clearSelectedCapacityFilters: state => {
      state.searchParameters = Object.assign(
        {},
        ...Object.entries(state.searchParameters)
          .filter(([key]) => !constants.capacityFilterList.includes(key))
          .map(([key, value]) => ({ [key]: value })),
      )
    },
    removeCapacityFilter: (state, action) => {
      state.searchParameters = Object.assign(
        {},
        ...Object.entries(state.searchParameters)
          .filter(([key]) => key !== `${action.payload}Min` && key !== `${action.payload}Max`)
          .map(([key, value]) => ({ [key]: value })),
      )
    },
    clearSearchStateOnVehicleTypeChange: state => {
      state.searchParameters = {
        ...INITIAL_STATE.searchParameters,
        vehicleTypes: state.searchParameters.vehicleTypes,
        leaseTypes: state.searchParameters.leaseTypes,
      }
    },
    clearAllSearchState: state => {
      return {
        ...INITIAL_STATE,
        leaseTypes: state.searchParameters.leaseTypes[0],
        searchParameters: {
          ...INITIAL_STATE.searchParameters,
          leaseTypes: [...state.searchParameters.leaseTypes],
          vehicleTypes: [...state.searchParameters.vehicleTypes],
          mileages: [],
          monthlyPaymentMax: 9999999,
        },
      }
    },
    storeIsSearchSaved: (state, action) => {
      state.isSearchSaved = action.payload
    },
    resetSearchState: () => {
      return INITIAL_STATE
    },
    landedOnCarSearchPage: () => {},
    landedOnCarSearchPageWithPath: () => {},
    landedOnCarSearchPageWithReference: () => {},
    landedOnVanSearchPage: () => {},
    landedOnVanSearchPageWithPath: () => {},
    landedOnVanSearchPageWithReference: () => {},
    saveSearch: () => {},
    removeVehicleRangesFromSearchParameters: () => {},
    getSearchResults: () => {},
    openSearchFilter: () => {},
    getRangesForManufacturers: () => {},
  },
})

export const findADeal = createAction('search/findADeal', values => {
  return {
    payload: values,
  }
})

export const findManufacturers = createAction('search/findManufacturers', values => {
  return {
    payload: values,
  }
})

export const {
  toggleVat,
  toggleInStock,
  toggleGrouping,
  updateInStockFromLeaseProfile,
  storeSearchTotals,
  storePagination,
  storeAvailableFilters,
  storeSearchResults,
  storeVehicleRanges,
  removeVehicleRanges,
  storeSearchParameters,
  removeSearchParameters,
  storeSearchMultiParameters,
  clearSelectedRangesForSelectedManufacturer,
  storeLeaseType,
  clearPageNumber,
  storeSearchFilter,
  clearSelectedSearchFilter,
  clearSearchParameters,
  clearSelectedRangeSearchFilter,
  storeTextSearch,
  storeSortBy,
  storeSavedSearchReference,
  storeSearchFiltersHaveChanged,
  clearTextSearch,
  storeHomepageSearchParameters,
  hydrateSearchParameters,
  toggleBudgetPrices,
  storeVehicleTypes,
  storeGroupedVehiclePriceTag,
  removeGroupedVehiclePriceTag,
  storeUngroupedVehiclePriceTag,
  removeUngroupedVehiclePriceTag,
  clearSelectedCapacityFilters,
  removeCapacityFilter,
  clearSearchStateOnVehicleTypeChange,
  clearAllSearchState,
  resetSearchState,
  landedOnCarSearchPage,
  landedOnCarSearchPageWithPath,
  landedOnCarSearchPageWithReference,
  landedOnVanSearchPage,
  landedOnVanSearchPageWithPath,
  landedOnVanSearchPageWithReference,
  getSearchResults,
  saveSearch,
  removeVehicleRangesFromSearchParameters,
  openSearchFilter,
  storeIsSearchSaved,
  getRangesForManufacturers,
} = searchSlice.actions
export default searchSlice.reducer
