import error, { INITIAL_STATE as errorState } from './error'
import filter, { INITIAL_STATE as filterState } from './filter'
import locoSpace, { INITIAL_STATE as locoSpaceState } from './locoSpace'
import loader, { INITIAL_STATE as loadersState } from './loader'
import search, { INITIAL_STATE as searchState } from './search'
import supplier, { INITIAL_STATE as supplierState } from './supplier'
import system, { INITIAL_STATE as systemState } from './system'
import user, { INITIAL_STATE as userState } from './user'
import leaseProfile, { INITIAL_STATE as leaseProfileState } from './leaseProfile'

export const initialState = {
  error: errorState,
  filter: filterState,
  locoSpace: locoSpaceState,
  loader: loadersState,
  search: searchState,
  supplier: supplierState,
  system: systemState,
  user: userState,
  leaseProfile: leaseProfileState,
}

export const rootReducers = {
  error,
  filter,
  locoSpace,
  loader,
  search,
  supplier,
  system,
  user,
  leaseProfile,
}
