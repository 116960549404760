import * as Sentry from '@sentry/nextjs'

export const ERRORS = {
  fetchingSearchResults: 'FETCHING_SEARCH_RESULTS',
  fetchingSavedEnquiries: 'FETCHING_SAVED_ENQUIRIES',
  fetchingAccountInfo: 'FETCHING_ACCOUNT_INFO',
  checkForUser: 'CHECK_FOR_USER',
  fetchingDeal: 'FETCHING_DEAL',
  fetchingVehicle: 'FETCHING_VEHICLE',
  fetchingHistoricalPriceData: 'FETCHING_HISTORICAL_PRICE_DATA',
  removingSavedEnquiry: 'REMOVING_SAVED_ENQUIRY',
  autoVerifyingUser: 'AUTO_VERIFYING_USER',
  fetchingBestDeals: 'FETCHING_BEST_DEALS',
  savingAccountInfo: 'SAVING_ACCOUNT_INFO',
  addingPriceAlert: 'ADDING_PRICE_ALERT',
  removingPriceAlert: 'REMOVING_PRICE_ALERT',
  fetchingSupplierList: 'FETCHING_SUPPLIER_LIST',
  savingDealForRewards: 'SAVING_DEAL_FOR_REWARDS',
  fetchingRewardsHistory: 'FETCHING_REWARDS_HISTORY',
  fetchingLeaseProfilePrices: 'FETCHING_LEASE_PROFILE_PRICES',
  savingSearch: 'SAVING_SEARCH',
  removingSavedSearch: 'REMOVING_SAVED_SEARCH',
  fetchingSavedSearches: 'FETCHING_SAVED_SEARCHES',
  fetchingSavedVehicles: 'FETCHING_SAVED_VEHICLES',
  updatingMarketingPreferences: 'UPDATING_MARKETING_PREFERENCES',
  checkingPriceAlertExists: 'CHECKING_PRICE_ALERT_EXISTS',
  sendingEnquiry: 'SENDING_ENQUIRY',
  sendingCallBack: 'SENDING_CALL_BACK',
  captchaHasExpired: 'CAPTCHA_HAS_EXPIRED',
  dealNoLongerExists: 'DEAL_NO_LONGER_EXISTS',
  sendingLeadVerificationCode: 'SENDING_LEAD_VERIFICATION_CODE',
  sendingAlternativeLead: 'SENDING_ALTERNATIVE_LEAD',
  calculatingPersonalPcpPrice: 'CALCULATING_PERSONAL_PCP_PRICE',
  fetchingAvailableLeaseProfiles: 'FETCHING_AVAILABLE_LEASE_PROFILES',
}
/**
 *
 * @type {{checkingPriceAlertExists: ErrorCode, removingSavedSearch: ErrorCode, fetchingVehicle: ErrorCode, fetchingAccountInfo: ErrorCode, dealNoLongerExists: ErrorCode, savingVehicleToGarage: ErrorCode, sendingLeadVerificationCode: ErrorCode, removingSavedEnquiry: ErrorCode, fetchingHistoricalPriceData: ErrorCode, fetchingLeaseProfilePrices: ErrorCode, savingAccountInfo: ErrorCode, sendingEnquiry: ErrorCode, captchaHasExpired: ErrorCode, autoVerifyingUser: ErrorCode, fetchingDeal: ErrorCode, fetchingRewardsHistory: ErrorCode, fetchingSearchResults: ErrorCode, savingSearch: ErrorCode, savingDealForRewards: ErrorCode, fetchingBestDeals: ErrorCode, fetchingSavedVehicles: ErrorCode, sendingCallBack: ErrorCode, sendingAlternativeLead: ErrorCode, fetchingSavedEnquiries: ErrorCode, fetchingSavedSearches: ErrorCode, updatingMarketingPreferences: ErrorCode, fetchingSupplierList: ErrorCode, checkForUser: ErrorCode, removingPriceAlertForVehicle: ErrorCode, removingSavedVehicle: ErrorCode}}
 */

export class NotFoundError extends Error {
  constructor(args) {
    super(args)
    this.name = 'NotFoundError'
  }
}

export class BadRequestError extends Error {
  constructor(args) {
    super(args)
    this.name = 'BadRequestError'
  }
}

export class InternalServerError extends Error {
  constructor(args) {
    super(args)
    this.name = 'InternalServerError'
  }
}

export class LocoError extends Error {
  constructor(args) {
    super(args)
    this.name = 'LocoError'
    this.message = args.message
    this.error = args.error || {}
    this.params = args.params || {}

    Sentry.captureException(this, { extra: { error: this.error, params: this.params } })
  }
}
