export const endpointKeys = {
  advancedLandingPageDeal: '/ad/deal',
  allSavedVehicles: '/user/garage/list',
  allSavedEnquiries: '/user/enquiries',
  allSavedSearches: '/user/search',
  availableLeaseProfiles: '/search/available-lease-profile',
  bestDealsByBodyStyle: '/search/home/featured',
  bodyStyles: '/search/filters/bodystyles',
  capacity: '/search/filters/capacity',
  careers: '/careers',
  checkPriceAlertExists: '/user/garage/deal',
  configuratorSearchRequest: '/search/vehicle-configurator',
  doors: '/search/filters/doors',
  driveTypes: '/search/filters/drivetypes',
  experimentList: '/visitors/experiments/groups',
  faqArticles: '/faqs/list/articles',
  features: '/search/filters/features',
  fuelTypes: '/search/filters/fueltypes',
  historicalPricesFromLeaseProfile: '/deal/history/profile',
  manufacturers: '/search/filters/manufacturers',
  newsletterSignup: '/marketing/communication/sign-up',
  pageContent: '/page-content',
  pageContentComparisonTemplate: '/page-content/comparison-template',
  pageContentList: '/page-content/list',
  pcpComparison: '/pcp/calculate',
  performance: '/search/filters/performance',
  postAnalyticsEvent: `/locolytics/sweep`,
  ranges: '/search/filters/ranges',
  removePriceAlert: '/user/garage/delete',
  removeEnquiry: '/user/enquiry/remove',
  removeSavedSearch: '/user/search/remove',
  requestLeadVerificationCode: '/lead/code/send',
  rewardsHistory: '/user/rewards/history',
  roofHeight: '/search/filters/roofheight',
  addPriceAlert: '/user/garage/add',
  saveEnquiry: '/user/enquiry/send',
  saveRewardDetails: '/user/rewards/save',
  saveSearch: '/user/search/save',
  saveUserDetails: '/user/details/update',
  search: '/search/v2',
  searchWithPath: '/search/url-path',
  searchWithReference: '/search/reference',
  searchByLeaseProfile: '/search/lease-profile',
  searchForVehicleComparison: '/search/vehicle-comparison',
  seats: '/search/filters/seats',
  sendAlternativeLeadVerification: '/lead/code/confirm',
  sendFeedback: '/feedback/send',
  sendLeadDetails: '/lead/register',
  sendLeadVerification: '/lead/confirm',
  sendPcpLeadDetails: '/lead/register/pcp',
  sendPoorCreditLeadDetails: '/lead/register/poor-credit',
  spec: '/search/filters/spec',
  supplierList: '/supplier/list',
  testimonials: '/testimonials',
  transmissionTypes: '/search/filters/transmissiontypes',
  trims: '/search/filters/trims',
  userDetails: '/user/details',
  userGetUserName: '/user/username',
  userMarketingPreferences: '/user/communication/preferences',
  vehicle: '/vehicle',
  vehicleComparisonData: '/vehicle/comparison-data',
  vehicleReview: '/vehicle/review',
  vehicleTechnicalData: '/vehicle/technical-data',
  vehicleStandardOptions: '/vehicle/standard-options',
  verifyEnquiry: '/lead/verify',
  wheelbase: '/search/filters/wheelbase',
}
