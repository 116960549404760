import app from './version.json'

export const constants = {
  locoDaySavedSearch: '42538abdd5657e5a2a2aea662531613b',
  localStorageKey: {
    website: 'leaseloco.website',
    visitorKey: 'leaseloco.visitorKey',
    visitorKeyTimestamp: 'leaseloco.visitorKeyTimestamp',
    touchpoints: 'leaseloco.touchpoints',
    garageSort: 'leaseloco.garageSort',
    savedSearchSort: 'leaseloco.savedSearchSort',
    newsletterPopup: 'leaseloco.newsletterPopup',
    promotionItemsOrder: 'leaseloco.promotionItemsOrder',
    saveToGaragePopup: 'leaseloco.saveToGaragePopup',
    locosnaps: 'leaseloco.locosnaps',
    hideLocosnaps: 'leaseloco.hideLocosnaps',
    dealsExitIntent: 'leaseloco.dealsExitIntent',
    version: 'leaseloco.version',
    messageBanner: 'leaseloco.messageBanner',
    oneTap: 'leaseloco.oneTap',
    dealPromotionModal: 'leaseloco.dealPromotion',
    experimentOverride: 'leaseloco.experimentOverride',
    dealsScannerModal: 'leaseloco.dealsScannerModal',
    adminMode: 'leaseloco.adminMode',
    userProfileBuilder: 'leaseloco.userProfileBuilder',
    dotDigitalId: 'leaseloco.dotDigitalId',
  },
  appName: 'web-main-next',
  googleSiteKey: '6Lc4xWgaAAAAADzCDMLh88RI-TScjLNeYxX8XcIw',
  googleSiteKeyInvisible: '6LeY6_ApAAAAAAi4hLWsjJ33CuV3QTgwTVtJub6-',
  version: app.version,
  currentVatRate: 20,
  fetchTimeout: 30000,
  numericalDateForm: 'DD-MM-YYYY',
  dateFormat: 'D MMM YYYY',
  blogDateFormat: 'DD MMM "YY',
  enquireDateFormat: 'Do MMMM YYYY',
  rewardDateFormat: 'Do MMM YYYY',
  historicalPriceDateFormat: {
    DAY: 'D MMM',
    WEEK: 'D MMM',
    MONTH: 'MMM',
  },
  pageSize: {
    garage: 5,
    enquiries: 10,
    savedSearches: 10,
    default: 10,
  },
  eventTypes: {
    pageView: 'PAGE_VIEW',
    addVehicleToGarage: 'ADD_VEHICLE_TO_GARAGE',
    removePriceAlert: 'REMOVE_PRICE_ALERT',
    addVehicleToComparison: 'ADD_VEHICLE_TO_COMPARISON',
    removeVehicleFromComparison: 'REMOVE_VEHICLE_FROM_COMPARISON',
    shareDeal: 'SHARE_DEAL',
    openCallSupplierModal: 'OPEN_CALL_SUPPLIER_MODAL',
    callSupplier: 'CLICK_TO_CALL_SUPPLIER',
    openMessageSupplierModal: 'OPEN_MESSAGE_SUPPLIER_MODAL',
    openCallbackMobileModal: 'OPEN_CALLBACK_MOBILE_MODAL',
    sendMessageToSupplier: 'SEND_MESSAGE_TO_SUPPLIER',
    arrangeCallBackForSupplier: 'ARRANGE_CALL_BACK_FOR_SUPPLIER',
    logIn: 'LOG_IN',
    logOut: 'LOG_OUT',
    signUp: 'SIGN_UP',
    alreadyVerified: 'ALREADY_VERIFIED',
    saveSearch: 'SAVE_SEARCH',
    shareSavedSearch: 'SHARE_SAVED_SEARCH',
    runSavedSearch: 'RUN_SAVED_SEARCH',
    removeSavedSearch: 'REMOVE_SAVED_SEARCH',
    headerBannerClick: 'HEADER_BANNER_CLICK',
    headerBannerRemove: 'HEADER_BANNER_REMOVE',
    searchRequest: 'SEARCH_REQUEST',
    leaseProfileRequest: 'LEASE_PROFILE_REQUEST',
    compareVehicles: 'COMPARE_VEHICLES',
    verifyLead: 'VERIFY_LEAD',
    verifyLeadCtaClick: 'VERIFY_LEAD_CTA_CLICK',
    verifyNewsletter: 'VERIFY_NEWSLETTER',
    newsletterSignup: 'NEWSLETTER_SIGNUP',
    newsletterSignUpModal: 'OPEN_NEWSLETTER_SIGN_UP_MODAL',
    getInsuranceQuote: 'GET_INSURANCE_QUOTE',
    getChargerQuote: 'GET_CHARGER_QUOTE',
    getSellYourVehicleQuote: 'GET_SELL_YOUR_VEHICLE_QUOTE',
    clickOnSocialLink: 'CLICK_ON_SOCIAL_LINK',
    enquiryOnDealLandingPage: 'ENQUIRY_ON_DEAL_LANDING_PAGE',
    leadPcpLandingPage: 'LEAD_PCP_LANDING_PAGE',
    leadDealPageCallback: 'LEAD_DEAL_PAGE_CALLBACK',
    leadDealLandingPage: 'LEAD_DEAL_LANDING_PAGE',
    leadPoorCreditLandingPage: 'LEAD_POOR_CREDIT_LANDING_PAGE',
    oneTapComponentView: 'ONE_TAP_COMPONENT_VIEW',
    oneTapComponentAction: 'ONE_TAP_COMPONENT_ACTION',
    enquiryDealPageMessage: 'ENQUIRY_DEAL_PAGE_MESSAGE',
    enquiryDealLandingPage: 'ENQUIRY_DEAL_LANDING_PAGE',
    enquiryDealPageCallback: 'ENQUIRY_DEAL_PAGE_CALLBACK',
    enquiryPcpLandingPage: 'ENQUIRY_PCP_LANDING_PAGE',
    enquiryPoorCreditLandingPage: 'ENQUIRY_POOR_CREDIT_LANDING_PAGE',
    landingPageQualifyingQuestionsCompleted: 'LANDING_PAGE_QUALIFYING_QUESTIONS_COMPLETED',
    landingPageQualifyingQuestionsFailed: 'LANDING_PAGE_QUALIFYING_QUESTIONS_FAILED',
    changeHistoricalGraphPeriod: 'CHANGE_HISTORICAL_GRAPH_PERIOD',
    viewTechnicalDetails: 'VIEW_TECHNICAL_DETAILS',
    viewStandardSpecDetails: 'VIEW_STANDARD_SPEC_DETAILS',
    enquirySalarySacrifice: 'ENQUIRY_SALARY_SACRIFICE',
    leaseProfileFilter: 'LEASE_PROFILE_FILTER',
    openEnquiryModal: 'OPEN_ENQUIRY_MODAL',
    viewEnquiryInputMask: 'VIEW_ENQUIRY_INPUT_MASK',
    removeEnquiryInputMask: 'REMOVE_ENQUIRY_INPUT_MASK',
    viewLeaseProfileMask: 'VIEW_LEASE_PROFILE_MASK',
    messageBannerCtaClick: 'CLICK_MESSAGE_BANNER_CTA',
    closeMessageBannerClick: 'CLOSE_MESSAGE_BANNER_CLICK',
    messageBannerViewCondition: 'MESSAGE_BANNER_VIEW_CONDITION',
    formInvalidPhoneNumber: 'FORM_INVALID_PHONE_NUMBER',
    mainNavigation: 'MAIN_NAVIGATION',
    dealPromotionModalView: 'DEAL_PROMOTION_MODAL_VIEW',
    dealPromotionModalAction: 'DEAL_PROMOTION_MODAL_ACTION',
    brandsModalView: 'BRANDS_MODAL_VIEW',
    brandsModalAction: 'BRANDS_MODAL_ACTION',
    compareDealsClick: 'COMPARE_DEALS_CLICK',
    createPriceAlert: 'CREATE_PRICE_ALERT',
    exitIntentAction: 'EXIT_INTENT_ACTION',
    openSearchFilter: 'OPEN_SEARCH_FILTER',
    closeSearchFilter: 'CLOSE_SEARCH_FILTER',
    formEnquiryOpened: 'FORM_ENQUIRY_OPENED',
  },
  insuranceUrl: {
    homePage: 'https://leaseloco.motor.moneysupermarket.insure',
    dealPage: 'https://leaseloco.motor.moneysupermarket.insure',
  },
  sellYourVehicleUrl: {
    homePage: 'https://motorway.co.uk',
    dealPage: 'https://motorway.co.uk',
  },
  partners: {
    insurance: 'moneysupermarket',
    sellYourVehicle: 'motorway',
    charging: '',
  },
  graphTooltipDateFormat: {
    DAY: 'D MMM',
    WEEK: 'D MMM',
    MONTH: 'D MMM',
  },
  imageHost: {
    vehicleImage: 'https://vehicle-images.leaseloco.com',
    vehicle: 'https://vehicle.images.leaseloco.com',
    supplier: 'https://supplier.images.leaseloco.com',
    social: 'https://assets.leaseloco.com/social/social-image.jpg',
    assets: 'https://assets.leaseloco.com',
  },
  defaultVehicleHeroImage: {
    VAN: 'https://leaseloco-vehicle-images.s3.eu-west-2.amazonaws.com/images/default/small/default.jpeg',
    CAR: 'https://leaseloco-vehicle-images.s3.eu-west-2.amazonaws.com/images/default/small/default.jpeg',
  },
  promotionalType: {
    INTERNAL: 'internal',
    INSURANCE: 'insurance',
    SELL_YOUR_VEHICLE: 'sell-your-vehicle',
  },
  errorMessages: {
    autoVerify: 'There has been an error verifying your account. Please try again later.',
    fetchingAccountInfo:
      'There has been an issue fetching your account information. Please try again later.',
    fetchingDeal: 'There has been an issue fetching this deal. Please try again later.',
    fetchingDeals: 'There has been an error fetching the deals. Please try again later.',
    fetchingMarketingPreferences:
      'There has been an issue fetching your account information. Please try again later.',
    fetchingSavedEnquiries:
      'There has been an issue fetching your saved enquiries. Please try again later.',
    fetchingSavedSearches:
      'There has been an issue fetching your user credentials. Please try again later.',
    historicalPrices:
      'There has been an issue fetching historical data for this deal. Please try again later.',
    removeSavedSearch:
      'There has been an issue removing your saved search. Please try again later.',
    removePriceAlert: 'There has been an issue removing your price alert. Please try again later.',
    savingAccountInfo:
      'There has been an issue saving your account information. Please try again later.',
    addingPriceAlert:
      'There has been an error setting up this price alert. Please try again later.',
    savingSearch: 'There has been an error saving this search. Please try again later.',
    sendingEnquiry: 'There has been an issue sending your enquiry. Please try again later.',
    sendingCallBack:
      'There has been an issue sending your call back request. Please try again later.',
    sendingCallBackVerifyCode: 'There has been an issue verifying your details. Please try again.',
    dealNoLongerExists: 'This deal is no longer available.',
    topDeals: "There has been an error fetching today's top deals. Please try again later.",
    updatingMarketingPreferences:
      'There has been an issue saving your account information. Please try again later.',
    userCredentials:
      'There has been an issue fetching your user credentials. Please try again later.',
    verifyingEnquiry: 'There has been an issue verifying your enquiry. Please try again later.',
    verifyingNewsletterSignup:
      'There has been an issue verifying your newsletter signup. Please try again later.',
  },
  currency: '£',
  loaderDelay: 1000,
  searchDebounceDelay: 650,
  analyticsEventDebounceDelay: 5000,
  financialConductAuthority: '841366',
  companiesHouseNumber: 'SC582183',
  companyAddress: '33 Arx Technology Centre, James Watt Avenue, Glasgow, G75 0QD',
  dataProtectionNumber: 'ZA336288',
  vatNumber: '304164344',
  validationMessages: {
    email: 'This is not a valid email',
    phone: 'This is not a valid phone number',
    required: 'Your :attribute is required',
    numeric: 'Your :attribute should be numbers only',
    max: 'Your phone number should be between 10 and 11 numbers',
    min: 'Your phone number should be between 10 and 11 numbers',
    promotionalCode: 'This is not a valid promotional code',
    name: 'This is not a valid name',
    captcha: 'You need to prove you are human by clicking the box',
    verificationCode: 'This is not a valid verification code',
  },
  emailAddresses: {
    support: 'support@leaseloco.com',
    careers: 'careers@leaseloco.com',
    contact: 'contact@leaseloco.com',
    complaints: 'complaints@leaseloco.com',
    closeAccount: 'closemyaccount@leaseloco.com',
    privacy: 'privacy@leaseloco.com',
  },
  graphFilters: {
    ONE_MONTH: '1M',
    SIX_MONTHS: '6M',
    ONE_YEAR: '1Y',
  },
  environments: {
    LOCAL: 'local',
    DEVELOPMENT: 'development',
    STAGING: 'staging',
    PRODUCTION: 'production',
  },
  vehicleImageSizes: {
    EXTRA_LARGE: 'extra-large',
    DISPLAY: 'display',
  },
  regExp: {
    findSpecialCharacters: /[[\]._()'"!/#\\]+/g,
    findPlus: /[+]/g,
    findAdvancedTelephoneNumber: /^0([1-6][0-9]{8,9}|7[0-9]{9})/,
    addCommasToNumbers: /\B(?=(\d{3})+(?!\d))/g,
    findName: /^(?=.{2,50}$)[A-Za-z]+(?:['\-.\s][A-Za-z]+)*$/,
    findSixDigitNumber: /^[0-9]{6}$/,
    findNewLines: /(\r\n|\n|\r)/gm,
  },
  roles: {
    dataPrivacyManager: 'John Wilmot',
  },
  sentry: {
    dsn: process.env.SENTRY_DSN,
  },
  federatedData: {
    // eslint-disable-next-line camelcase
    google_client_id: process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID,
    // eslint-disable-next-line camelcase
    facebook_app_id: process.env.NEXT_PUBLIC_FACEBOOK_APP_ID,
  },
  metaData: {
    title: "UK's Biggest Car Lease Comparison Site | Millions of Vehicle Leasing Deals | LeaseLoco",
    tagLine:
      'The Hottest Car Lease Deals Ranked by the Most Powerful Search in the Market. Instantly Find & Compare Millions of Car Lease Deals Online at LeaseLoco.',
    description:
      'LeaseLoco is the biggest car leasing comparison site in the UK. Compare more car lease deals than anyone else. Arriba!',
    vanTitle: 'Van Leasing Comparison | Van Lease Deals | LeaseLoco',
    vanTagLine:
      'Van leasing comparison from LeaseLoco. The hottest van lease deals ranked by the best value score in the market. Get your business moving today!',
  },
  searchAlertMessages: {
    carDefault:
      'There are no cars on the market with your search criteria. Be a bit less specific with some filters.',
    withTextSearch:
      "We can't find what you're looking for. Be a bit less specific with some filters.",
    vanDefault:
      'There are no vans on the market with your search criteria. Be a bit less specific with some filters.',
  },
  searchSortingOptions: {
    VALUE_HIGH_TO_LOW: {
      label: 'Score: High to low',
      value: 1,
    },
    VALUE_LOW_TO_HIGH: {
      label: 'Score: Low to high',
      value: 3,
    },
    PRICE_HIGH_TO_LOW: {
      label: 'Cost: High to low',
      value: 4,
    },
    PRICE_LOW_TO_HIGH: {
      label: 'Cost: Low to high',
      value: 2,
    },
  },
  garageSortingOptions: {
    OLDEST_FIRST: 1,
    NEWEST_FIRST: 2,
  },
  newsletterLeaseDates: [
    {
      label: 'Under 4 weeks',
      value: 'Under 4 weeks',
    },
    {
      label: '1 to 3 months',
      value: '1 to 3 months',
    },
    {
      label: '3 to 6 months',
      value: '3 to 6 months',
    },
    {
      label: '6 to 12 months',
      value: '6 to 12 months',
    },
    {
      label: 'Over 1 year',
      value: 'Over 1 year',
    },
  ],
  newsletterMonthlyBudget: [
    {
      label: 'Under £150',
      value: 'Under £150',
    },
    {
      label: 'Under £200',
      value: 'Under £200',
    },
    {
      label: 'Under £300',
      value: 'Under £300',
    },
    {
      label: 'Under £400',
      value: 'Under £400',
    },
    {
      label: 'Under £500',
      value: 'Under £500',
    },
    {
      label: 'Over £500',
      value: 'Over £500',
    },
  ],
  contactUs: {
    feedback: {
      h2: `Feedback & Support`,
      copy: "We'd love your feedback on our product! Got a new feature suggestion? Found a bug (hope not!)? Or maybe you just need help with something?*\n\nWe'd love to hear from you!\n\nIf you are new to leasing, a great starting point is [our 'Why Lease' guide](/why-lease).\n\nAnd don't forget to check out [our FAQs](/faqs) as this is a great way to quickly find the answers to the most common questions we are asked.\n\n*Please don't enquire for a vehicle through this form. Use our search to find the deal for you and contact the relevant broker.",
      textArea: {
        label: `Feedback Message`,
        placeholder: `Your feedback...`,
      },
      email: {
        label: `If you want us to reply to your message, please leave your email address:`,
      },
      success: `Thanks for your feedback. If you've included your email address then we'll be in touch as quick as we can.`,
    },
    advertise: {
      h2: `Want To Advertise With Us?`,
      copy: "Like what we're doing and want your lease deals on our site?\n\nLeave a message and email address below and one of the team will get back to you.",
      textArea: {
        label: `Your message`,
        placeholder: `Your message...`,
      },
      email: {
        label: `Your email address:`,
      },
      success: `Thanks for your message. We'll be in touch as quick as we can.`,
    },
  },
  headerBanner: {
    DEFAULT: 'default',
    HALLOWEEN: 'halloween',
    BLACK_FRIDAY: 'black-friday',
    CHRISTMAS: 'christmas',
  },
  vehicleConfigTypes: {
    addOn: 'addOn',
    bodyStyle: 'bodyStyle',
    derivative: 'vehicleId',
    driveType: 'driveType',
    engine: 'engine',
    fuelType: 'fuelType',
    stockStatus: 'stockStatus',
    transmissionType: 'transmissionType',
    trim: 'trim',
  },
  vehicleConfigModes: {
    BEST_VALUE: 1,
    LOWEST_PRICE: 2,
  },
  footerLinks: [
    {
      slug: '/car-leasing/audi',
      label: 'Audi lease deals',
      vehicleType: 1,
    },
    {
      slug: '/car-leasing/bmw',
      label: 'BMW lease deals',
      vehicleType: 1,
    },
    {
      slug: '/car-leasing/volkswagen',
      label: 'Volkswagen lease deals',
      vehicleType: 1,
    },
    {
      slug: '/car-leasing/mercedes-benz',
      label: 'Mercedes-Benz lease deals',
      vehicleType: 1,
    },
    {
      slug: '/car-leasing/kia',
      label: 'Kia lease deals',
      vehicleType: 1,
    },
    {
      slug: '/car-leasing/land-rover',
      label: 'Land Rover lease deals',
      vehicleType: 1,
    },
    {
      slug: '/car-leasing/volvo',
      label: 'Volvo lease deals',
      vehicleType: 1,
    },
    {
      slug: '/van-leasing/ford/ranger',
      label: 'Ford Ranger lease deals',
      vehicleType: 2,
    },
    {
      slug: '/van-leasing/ford/transit-custom',
      label: 'Ford Transit Custom lease deals',
      vehicleType: 2,
    },
    {
      slug: '/van-leasing/volkswagen/transporter',
      label: 'Volkswagen Transporter lease deals',
      vehicleType: 2,
    },
    {
      slug: '/van-leasing/mercedes-benz/vito',
      label: 'Mercedes-Benz Vito lease deals',
      vehicleType: 2,
    },
    {
      slug: '/van-leasing/renault/trafic',
      label: 'Renault Trafic lease deals',
      vehicleType: 2,
    },
    {
      slug: '/van-leasing/toyota/hilux',
      label: 'Toyota Hilux lease deals',
      vehicleType: 2,
    },
    {
      slug: '/van-leasing/vauxhall/vivaro',
      label: 'Vauxhall Vivaro lease deals',
      vehicleType: 2,
    },
  ],
  capacityFilterList: [
    'payloadMin',
    'payloadMax',
    'loadLengthMin',
    'loadLengthMax',
    'loadHeightMin',
    'loadHeightMax',
    'loadWidthMin',
    'loadWidthMax',
  ],
  accountSections: {
    PERSONAL: { label: 'Account details', route: 'account-details' },
    PRICE_ALERTS: { label: 'Price alerts', route: 'price-alerts' },
    SAVED_SEARCHES: { label: 'Saved searches', route: 'saved-searches' },
    ENQUIRIES: { label: 'My enquiries', route: 'enquiries' },
    REFER_A_FRIEND: { label: 'Refer a friend', route: 'refer-a-friend' },
    REWARDS: { label: 'Rewards', route: 'rewards' },
    COMPARISON: { label: 'Comparison', route: 'comparison', hidden: true },
  },
  configPageSections: {
    EDIT_LEASE: 'Edit lease',
    OVERVIEW: 'Overview',
    SPECIFICATION: 'Specification',
    GALLERY: 'Gallery',
    PRICE_HISTORY: 'Price history',
    REVIEW: 'Review',
  },
  dealsPageSections: {
    EDIT_LEASE: 'Edit lease',
    OVERVIEW: 'Overview',
    FAQS: 'FAQs',
    SAVE_FOR_LATER: 'Save for later',
  },
  faqCategories: {
    1: 'How does Leasing a Car Work?',
    2: "What's Included in a Car Lease?",
    3: 'What are some of the details involved in leasing a car?',
    4: 'Car Leasing Eligibility - Can I Lease a Car?',
    5: 'Business vs Personal Car Leasing',
    6: 'LeaseLoco Website Features',
    7: 'Amazon Rewards with LeaseLoco',
  },
  componentStatus: {
    LOADING: 'loading',
    ERROR: 'error',
    COMPLETE: 'complete',
    NOT_FOUND: 'not-found',
    DEFAULT: 'default',
  },
  enquiryStatus: {
    NOT_SENT: 'not-sent',
    SENDING: 'sending',
    SENT: 'sent',
    ERROR: 'error',
    VERIFY: 'verify',
    VERIFIED: 'verified',
  },
  qualifyingQuestions: {
    one: "This is a 2 year lease with fixed monthly payments - in most cases you won't have the option to purchase the car at the end of the term.",
    two: 'If you go on to place an order for this lease deal, you will be required to pass a credit check.',
    three:
      'Are you happy for our leasing partner to contact you by email and/or phone to assist with your enquiry?',
  },
  qualifyingQuestionStatus: {
    QUESTIONS: 'questions',
    VALIDATE: 'validate',
    SHOW_MESSAGE: 'show-message',
  },
  leadStatus: {
    QUALIFY: 'qualify',
    CAPTURE: 'capture',
    REVIEW: 'review',
    VERIFY: 'verify',
    VERIFIED: 'verified',
  },
  trustpilot: {
    score: 4.8,
    businessUnitId: '5c658ae01fb4c90001848280',
    link: 'leaseloco.com',
    templates: {
      MICRO_STAR: {
        id: '5419b732fbfb950b10de65e5',
        height: '24px',
      },
    },
    reviewUrl: 'https://uk.trustpilot.com/review',
  },
  navigation: [
    {
      label: 'My Account',
      subnav: [
        {
          label: 'Account details',
          slug: '/locospace/account-details',
          category: 'GENERIC',
        },
        {
          label: 'Saved searches',
          slug: '/locospace/saved-searches',
          category: 'GENERIC',
        },
        {
          label: 'Enquiries',
          slug: '/locospace/enquiries',
          category: 'GENERIC',
        },
        {
          label: 'Refer a friend',
          slug: '/locospace/refer-a-friend',
          category: 'GENERIC',
        },
        {
          label: 'Rewards',
          slug: '/locospace/rewards',
          category: 'GENERIC',
        },
      ],
    },
  ],
  footer: {
    firstColumn: [
      {
        label: 'FAQs',
        slug: '/faqs',
      },
      {
        label: 'Guides',
        slug: '/guides',
      },
      {
        label: 'Blog',
        slug: '/blog',
      },
      {
        label: 'Careers',
        slug: '/careers',
      },
    ],
    secondColumn: [
      {
        label: 'Electric & Hybrid',
        slug: '/electric-car-leasing',
      },
      {
        label: 'In stock lease deals',
        slug: '/car-leasing/in-stock',
      },
      {
        label: 'Business',
        slug: '/business-leasing',
      },
      {
        label: 'Personal',
        slug: '/personal-car-leasing',
      },
      {
        label: 'Car leasing deals',
        slug: '/car-leasing/search',
      },
      {
        label: 'Van leasing deals',
        slug: '/van-leasing/search',
      },
    ],
  },
}

export const STATUS_PRE_REGISTER = 'pre-register'
export const STATUS_REGISTER = 'register'
export const STATUS_VERIFY = 'verify'
export const STATUS_VERIFY_FROM_SIGN_UP = 'verify-from-signup'
export const STATUS_ALREADY_VERIFIED = 'already-verified'
export const STATUS_LOGIN = 'login'
export const STATUS_GOOGLE_LOGIN = 'googleLogin'
export const STATUS_FACEBOOK_LOGIN = 'facebookLogin'
export const STATUS_FORGOT_PASSWORD = 'forgotPassword'
export const STATUS_PASSWORD_RESET_EMAIL_SENT = 'passwordResetEmailSent'
export const STATUS_NEW_PASSWORD = 'newPassword'
export const STATUS_GATHER_MOBILE_NUMBER = 'gather-mobile'
export const STATUS_PASSWORD_COMPLETE = 'passwordResetComplete'

export const FILTER_BODY_STYLES = 'bodyStyles'
export const FILTER_FUEL_TYPES = 'fuelTypes'
export const FILTER_INITIAL_PAYMENT_MIN = 'initialPaymentMin'

export const FILTER_INITIAL_PAYMENT_MAX = 'initialPaymentMax'
export const FILTER_INITIAL_PAYMENT_IN_MONTHS = 'initialPaymentInMonths'
export const FILTER_LEASE_TYPES = 'leaseTypes'
export const FILTER_LEASE_TYPES_BUSINESS = 1

export const FILTER_LEASE_TYPES_PERSONAL = 2
export const FILTER_MANUFACTURERS = 'manufacturers'
export const FILTER_RANGES = 'ranges'
export const FILTER_VEHICLE_TAGS = 'vehicleTags'

export const FILTER_MILEAGES = 'mileages'
export const FILTER_MONTHLY_PAYMENT_MAX = 'monthlyPaymentMax'
export const FILTER_MONTHLY_PAYMENT_MIN = 'monthlyPaymentMin'
export const FILTER_IN_STOCK = 'inStock'

export const FILTER_PAGE = 'page'
export const FILTER_PERFORMANCE = 'performance'
export const FILTER_SPEC = 'spec'
export const FILTER_FEATURES = 'features'

export const FILTER_TERMS = 'terms'
export const FILTER_TRANSMISSION_TYPES = 'transmissionTypes'
export const FILTER_TRIMS = 'trims'
export const FILTER_DRIVE_TYPES = 'driveTypes'

export const FILTER_DOORS = 'doors'
export const FILTER_SEATS = 'seats'
export const FILTER_CAPACITY = 'capacity'
export const FILTER_GROUPING = 'grouping'

export const FILTER_CAPACITY_PAYLOAD = 'payload'
export const FILTER_CAPACITY_PAYLOAD_MIN = 'payloadMin'
export const FILTER_CAPACITY_PAYLOAD_MAX = 'payloadMax'

export const FILTER_CAPACITY_LOAD_LENGTH = 'loadLength'
export const FILTER_CAPACITY_LOAD_LENGTH_MIN = 'loadLengthMin'
export const FILTER_CAPACITY_LOAD_LENGTH_MAX = 'loadLengthMax'

export const FILTER_CAPACITY_LOAD_HEIGHT = 'loadHeight'
export const FILTER_CAPACITY_LOAD_HEIGHT_MIN = 'loadHeightMin'
export const FILTER_CAPACITY_LOAD_HEIGHT_MAX = 'loadHeightMax'

export const FILTER_CAPACITY_LOAD_WIDTH = 'loadWidth'
export const FILTER_CAPACITY_LOAD_WIDTH_MIN = 'loadWidthMin'
export const FILTER_CAPACITY_LOAD_WIDTH_MAX = 'loadWidthMax'

export const FILTER_WHEELBASE = 'wheelbase'
export const FILTER_ROOF_HEIGHT = 'roofHeight'
export const FILTER_LEAD_TIMES = 'leadTimes'

export const FILTER_INCLUSIVE_BENEFITS = 'inclusiveBenefits'

export const LEASE_PROFILE_FILTER_MILEAGES = 'mileage'
export const LEASE_PROFILE_FILTER_TERMS = 'term'
export const LEASE_PROFILE_FILTER_IN_STOCK = 'inStock'
export const LEASE_PROFILE_FILTER_INITIAL_PAYMENT_IN_MONTHS = 'initialPaymentInMonths'
export const LEASE_PROFILE_FILTER_STOCK_STATUS = 'stockStatus'
export const LEASE_PROFILE_FILTER_LEASE_TYPE = 'leaseType'

export const LEASE_PROFILE_FILTER_MILEAGES_VALUES = [
  5000, 8000, 10000, 12000, 15000, 20000, 25000, 30000, 35000,
]
export const LEASE_PROFILE_FILTER_TERMS_VALUES = [12, 18, 24, 36, 48]
export const LEASE_PROFILE_FILTER_INITIAL_PAYMENT_IN_MONTHS_VALUES = [1, 3, 6, 9, 12]

export const FILTER_VEHICLE_TYPES = 'vehicleTypes'
export const FILTER_VEHICLE_TYPES_CARS = 1
export const FILTER_VEHICLE_TYPES_VANS = 2

export const FILTER_STOCK_STATUS_IN_STOCK = 1
export const FILTER_STOCK_STATUS_FACTORY_ORDER = 0

export const SNAPS_STATUS_INFO = 'INFO'
export const SNAPS_STATUS_DEALS = 'DEALS'

export const BUTTON_SIZE_XL = 'xl'
export const BUTTON_SIZE_LG = 'lg'
export const BUTTON_SIZE_MD = 'md'
export const BUTTON_SIZE_SM = 'sm'
export const BUTTON_SIZE_XS = 'xs'
export const BUTTON_HIERARCHY_FILLED = 'filled'
export const BUTTON_HIERARCHY_PLAIN = 'plain'
export const BUTTON_HIERARCHY_GREY = 'grey'
export const BUTTON_HIERARCHY_BLACK = 'black'
export const BUTTON_HIERARCHY_WHITE = 'white'
export const BUTTON_HIERARCHY_DARK = 'dark'
export const ICON_SIZE_SM = 16
export const ICON_SIZE_MD = 20
export const ICON_SIZE_LG = 24
export const ICON_SIZE_XL = 40
export const BADGE_COLOR_GREY = 'grey'
export const BADGE_COLOR_PINK = 'pink'
export const BADGE_COLOR_YELLOW = 'yellow'
export const BADGE_COLOR_GREEN = 'green'
export const BADGE_COLOR_BLACK = 'black'
export const BADGE_COLOR_GRADIENT = 'gradient'
