import { configureStore } from '@reduxjs/toolkit'
import { compare } from 'compare-versions'
import { createEpicMiddleware } from 'redux-observable'

import { rootEpic } from './epics'
import { rootReducers } from './reducers'
import { constants } from '@/lib/constants'
import { debounce, isProd, storageAvailable } from '@/lib/utilities/system'

const LOCAL_STORAGE_WRITE_DELAY = 50

const serialiseState = () => {
  if (typeof window !== 'undefined' && storageAvailable('localStorage')) {
    window.localStorage.setItem(constants.localStorageKey.website, JSON.stringify(store.getState()))
  }
}

const deserialiseState = () => {
  if (typeof window !== 'undefined' && storageAvailable('localStorage')) {
    const store = window.localStorage.getItem(constants.localStorageKey.website)
    const metaVersion = window.localStorage.getItem(constants.localStorageKey.version)

    return store &&
      metaVersion &&
      compare(JSON.parse(store).system.version || '0.0.0', metaVersion || '0.0.1', '=')
      ? JSON.parse(store)
      : {}
  }

  return {}
}

export const makeStore = () => {
  const epicMiddleware = createEpicMiddleware()

  const store = configureStore({
    reducer: {
      error: rootReducers.error,
      locoSpace: rootReducers.locoSpace,
      filter: rootReducers.filter,
      loader: rootReducers.loader,
      search: rootReducers.search,
      supplier: rootReducers.supplier,
      system: rootReducers.system,
      user: rootReducers.user,
      leaseProfile: rootReducers.leaseProfile,
    },
    preloadedState: deserialiseState(),
    middleware: getDefaultMiddleware => {
      if (!isProd()) {
        const { createLogger } = require('redux-logger')
        const logger = createLogger({ collapsed: true })

        return getDefaultMiddleware().concat(epicMiddleware).concat(logger)
      }

      return getDefaultMiddleware().concat(epicMiddleware)
    },
  })
  epicMiddleware.run(rootEpic)

  if (typeof window !== 'undefined' && storageAvailable('localStorage')) {
    store.subscribe(debounce(serialiseState, LOCAL_STORAGE_WRITE_DELAY))
  }

  return store
}

const store = makeStore()

export default store
